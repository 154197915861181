<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add mb-1">
      <b-col cols="12" md="5" xl="4">
        <b-form-checkbox
          v-model="reportSearchSection"
          @change="
            () => (this.showReportSearchSection = !this.showReportSearchSection)
          "
          ><b><u>show search section</u></b></b-form-checkbox
        >
      </b-col>
    </b-row>
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->

      <b-col
        v-if="reportSearchSection"
        cols="12"
        md="5"
        xl="4"
        class="invoice-actions"
      >
        <!-- Action Buttons -->

        <b-card>
          <!-- <b-tabs justified>
            <b-tab @click="onTabChange" title="Single"> -->
          <b-form-group label="Report Type">
            <!-- @change="onReportChange" -->
            <b-form-radio-group
              id="radio-group-3"
              v-model="reportTypeValue"
              name="radio-sub"
            >
              <b-form-radio name="some-radios1" value="default"
                >Default Report</b-form-radio
              >
              <b-form-radio name="some-radios1" value="territory"
                >Report With Territory
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <b-form-group label="Show Report By" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              @change="onOrderChange"
              id="radio-group-2"
              v-model="orderValue"
              :aria-describedby="ariaDescribedby"
              name="radio-sub-component"
            >
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="vendor"
                >Licensor</b-form-radio
              >
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="movie"
                >Movie</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
          <div v-if="orderValue == 'vendor'">
            <b-form-group label="Licensor">
              <v-select
                @input="getLicensorMovies('no')"
                v-model="selectedCustomer"
                placeholder="Licensor"
                label="company"
                :options="optionVendors"
              />
            </b-form-group>
            <b-form-group label="Movie">
              <v-select
                @input="onSelect($event)"
                v-model="selectedMovie"
                placeholder="Movie"
                label="multi_movies"
                :options="optionMultipleMovies"
              />
            </b-form-group>
          </div>
          <div v-else>
            <b-form-group label="Movie">
              <v-select
                @input="getMoviesLicensor"
                v-model="selectedMovie"
                placeholder="Movie"
                label="full_title"
                :options="optionMovies"
              />
            </b-form-group>
            <b-form-group label="Licensor">
              <v-select
                v-model="selectedCustomer"
                placeholder="Licensor"
                label="company"
                :options="optionCustomer"
              />
            </b-form-group>
          </div>

          <b-form-group v-if="tabIndex == 0" label="Revenue Date Range">
            <flat-pickr
              v-model="revenueDateRange"
              placeholder="Revenue Date"
              class="form-control"
              :config="{ mode: 'range', ...$flatPickrConfig }"
            />
          </b-form-group>
          <b-form-group
            v-if="
              tabIndex == 0 &&
              optionSearchTerritory.length &&
              reportTypeValue != 'default'
            "
            label="Territory"
          >
            <v-select
              v-model="selectedTempTerritory"
              multiple
              :reduce="(terr) => terr.id"
              placeholder="Territory"
              label="ter"
              :options="optionSearchTerritory"
            />
          </b-form-group>

          <b-button
            v-if="tabIndex == 0"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="getReportContracts"
            :disabled="
              (selectedCustomer || selectedMovie) &&
              revenueDateRange &&
              revenueDateRange.split('to').length == 2
                ? false
                : true
            "
          >
            Fetch Royalty Report
          </b-button>
          <!-- </b-tab>
            <b-tab @click="onTabChange" title="Multiple" lazy>
              <b-form-group label="Licensor">
                <v-select
                  @input="getLicensorMovies('yes')"
                  v-model="selectedCustomer"
                  placeholder="Licensor"
                  label="company"
                  :options="optionVendors"
                />
              </b-form-group>
              <b-form-group label="Movie">
                <v-select
                  v-model="selectedMovie"
                  placeholder="Movie"
                  label="multi_movies"
                  :options="optionMultipleMovies"
                />
              </b-form-group>

              <b-form-group label="Revenue Date Range">
                <flat-pickr
                  v-model="revenueDateRange"
                  placeholder="Revenue Date"
                  class="form-control"
                  :config="{ mode: 'range', ...$flatPickrConfig }"
                />
              </b-form-group>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                class="mb-75"
                block
                @click="getReportContracts"
                :disabled="
                  !selectedCustomer ||
                  !selectedMovie ||
                  !revenueDateRange ||
                  revenueDateRange.split('to').length < 2
                    ? true
                    : false
                    ? true
                    : false
                "
              >
                Generate Report
              </b-button>
            </b-tab>
          </b-tabs> -->

          <b-button
            v-if="tabIndex == 2 || tabIndex == 1"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="searchSavedReports"
          >
            Search
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            block
            @click="resetForm"
          >
            Reset
          </b-button>

          <!-- Button: Print -->
        </b-card>
        <table
          style="background: whiteSmoke"
          v-if="royaltyReportData && tabIndex == 0"
        >
          <tr>
            <td class="text-wrap text-left">
              Deduction P&A From Cinematic Gross Receipts (Cost Off Top Deal)
            </td>
            <td>
              {{ royaltyReportData.deductionPaFromCinemeticGrossReceipts }}
            </td>
          </tr>
          <tr>
            <td class="text-wrap text-left">
              Deduction MG From Cinematic Gross Receipts
            </td>
            <td>
              {{ royaltyReportData.deductionMgFromCinematicGrossReceipts }}
            </td>
          </tr>
          <tr>
            <td class="text-wrap text-left">
              Distributor Corridor Prior To Recoupement Of P&A
            </td>
            <td>
              {{ royaltyReportData.distributorCorridorPriorToRecoupementOfPa }}
            </td>
          </tr>
          <tr>
            <td class="text-wrap text-left">
              Distributor Corridor Prior To Recoupement of MG
            </td>
            <td>
              {{ royaltyReportData.distributorCorridorPriorToRecoupementOfMg }}
            </td>
          </tr>
          <tr>
            <td class="text-wrap text-left">
              Distributor Corridor Prior To Recoupement Of P&A and MG
            </td>
            <td>
              {{
                royaltyReportData.distributorCorridorPriorToRecoupementOfPaAndMg
              }}
            </td>
          </tr>
          <tr>
            <td class="text-wrap text-left">
              Licensor Corridor Prior To Recoupement Of P&A
            </td>
            <td>
              {{ royaltyReportData.licensorCorridorPriorToRecoupementOfPa }}
            </td>
          </tr>
          <tr>
            <td class="text-wrap text-left">
              Licensor Corridor Prior To Recoupement Of MG
            </td>
            <td>
              {{ royaltyReportData.licensorCorridorPriorToRecoupementOfMg }}
            </td>
          </tr>
        </table>
      </b-col>
      <b-col
        cols="12"
        :xl="reportSearchSection ? '8' : '12'"
        :md="reportSearchSection ? '7' : '12'"
      >
        <div>
          <b-tabs pills v-model="tabIndex">
            <hr class="m-1" />
            <b-tab title="Royalty Report" active>
              <b-row>
                <div style="overflow-x: auto; white-space: nowrap">
                  <div v-if="selectedRotaltyContracts.length">
                    <span>Selected Reports</span>

                    <b-badge variant="light-primary" @click="onGenrateAll"
                      >generate for all selected</b-badge
                    >
                  </div>
                  <b-list-group horizontal>
                    <b-list-group-item
                      v-for="(item, index) in selectedRotaltyContracts"
                      :key="index"
                      :active="
                        selectedContractID == item.contract_id ? true : false
                      "
                      @click="getRoyaltyReportMultiple(item)"
                      >Report {{ index + 1 }}</b-list-group-item
                    >
                  </b-list-group>
                </div>
              </b-row>
              <b-alert
                class="mt-1"
                v-if="royaltyReportData"
                variant="dark"
                show
              >
                <h4 class="alert-heading">
                  <span>
                    <b
                      >Licensor :
                      {{ selectedCustomer ? selectedCustomer.company : "" }}<br
                    /></b>
                    <b
                      >Movie : {{ royaltyReportData.totalColumn.contractMovies
                      }}<br
                    /></b>

                    <b
                      >Territories :
                      {{ royaltyReportData.totalColumn.contractTerritories
                      }}<br
                    /></b>

                    <b
                      >Statement Period :
                      {{ royaltyReportData.totalColumn.reportDateRange }}</b
                    >
                  </span>
                </h4>
              </b-alert>
              <b-alert variant="dark" show>
                <h4 class="alert-heading">
                  For royalty report please provide required fields and click
                  generate report.
                </h4>
              </b-alert>

              <div v-if="royaltyReportData">
                <b-row>
                  <b-col md="6">
                    <b-form-group label="sent to">
                      <b-form-input
                        id="event-description"
                        v-model="reportSentTo"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Comments"
                      label-for="event-description"
                    >
                      <b-form-textarea
                        id="event-description"
                        v-model="reportDescription"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <div style="overflow-x: auto">
                  <table
                    style="border: 1px solid black"
                    ref="rrt_table"
                    id="rrt_table"
                    v-if="royaltyReportData"
                  >
                    <tr class="spacing">
                      <th class="text-left" colspan="4">Rental Theatrical</th>
                      <th>Admissions</th>
                      <th>Box Office</th>
                      <th>Rental</th>
                      <th>Total</th>
                    </tr>
                    <tr
                      v-for="(
                        main, indexmain
                      ) in royaltyReportData.rentalSection"
                      :key="indexmain"
                    >
                      <td
                        :colspan="reportTypeValue == 'default' ? 4 : 3"
                        style="width: 100px"
                        class="smallWidth text-left"
                      >
                        {{
                          indexmain == 0
                            ? "Accumulated"
                            : indexmain == 1
                            ? "This Period"
                            : "Total"
                        }}
                      </td>
                      <td v-if="reportTypeValue != 'default'" class="text-left">
                        <span
                          v-for="(terr, terrIndex) in optionTempTerritory"
                          :key="terrIndex"
                        >
                          {{ terr.ter }}
                          <br
                        /></span>
                      </td>
                      <td v-for="(child, indexchild) in main" :key="indexchild">
                        <span
                          v-for="(subChild, indexsubchild) in child"
                          :key="indexsubchild"
                        >
                          <div class="text-nowrap">
                            <b v-if="indexchild == 'admission'">
                              {{ subChild.totalValue }}</b
                            >
                            <b v-else>
                              {{ royaltyReportData.totalColumn.currency }}
                              {{ formatCurrency(subChild.totalValue) }}</b
                            >
                          </div>
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td class="text-left" colspan="7">Total Rental</td>
                      <td>
                        <div class="text-nowrap">
                          <b>
                            {{ royaltyReportData.totalColumn.currency }}
                            {{
                              formatCurrency(
                                royaltyReportData.totalColumn.rentalTotalSum
                              )
                            }}</b
                          >
                        </div>
                      </td>
                    </tr>

                    <tr class="spacing">
                      <th class="text-left" colspan="8">Less</th>
                    </tr>

                    <tr>
                      <td
                        class="text-left"
                        :colspan="reportTypeValue == 'default' ? 7 : 5"
                      >
                        P&A
                      </td>
                      <td v-if="reportTypeValue != 'default'" class="text-left">
                        <span
                          v-for="(terr, terrIndex) in optionTempTerritory"
                          :key="terrIndex"
                        >
                          {{ terr.ter }}
                          <br
                        /></span>
                      </td>
                      <td v-if="reportTypeValue != 'default'">
                        <span
                          v-for="(
                            main, indexmain
                          ) in royaltyReportData.pnaSection"
                          :key="indexmain"
                        >
                          <div class="text-nowrap">
                            <b>
                              {{ royaltyReportData.totalColumn.currency }}
                              {{ formatCurrency(main.totalValue) }}</b
                            >
                          </div></span
                        >
                      </td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn.pnaTotalSum
                            )
                          }}</b
                        >
                      </td>
                    </tr>
                    <tr>
                      <td colspan="7" class="text-left">MG</td>

                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn.mgTotalSum
                            )
                          }}</b
                        >
                      </td>
                    </tr>

                    <tr>
                      <td
                        :colspan="reportTypeValue == 'default' ? 7 : 5"
                        class="text-left"
                      >
                        Distributor Corridor Prior To Recoupement Of P&A -
                        {{
                          royaltyReportData.distributorCorridorPriorToRecoupementOfPaPercent
                        }}%
                      </td>
                      <td v-if="reportTypeValue != 'default'" class="text-left">
                        <span
                          v-for="(terr, terrIndex) in optionTempTerritory"
                          :key="terrIndex"
                        >
                          {{ terr.ter }}
                          <br
                        /></span>
                      </td>
                      <td v-if="reportTypeValue != 'default'">
                        <span
                          v-for="(
                            main, indexmain
                          ) in royaltyReportData.distributorCorridorPriorToRecoupementOfPaSection"
                          :key="indexmain"
                        >
                          <b>
                            {{ royaltyReportData.totalColumn.currency }}
                            {{ formatCurrency(main.totalValue) }} </b
                          ><br
                        /></span>
                      </td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn
                                .distributorCorridorPriorToRecoupementOfPaSectionTotal
                            )
                          }}</b
                        >
                      </td>
                    </tr>
                    <tr>
                      <td colspan="7" class="text-left">
                        Distributor Corridor Prior To Recoupement Of MG -
                        {{
                          royaltyReportData.distributorCorridorPriorToRecoupementOfMgPercent
                        }}%
                      </td>

                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn
                                .distributorCorridorPriorToRecoupementOfMgTotal
                            )
                          }}</b
                        >
                      </td>
                    </tr>
                    <tr>
                      <td colspan="7" class="text-left">
                        Distributor Corridor Prior To Recoupement Of P&A And MG
                        -
                        {{
                          royaltyReportData.distributorCorridorPriorToRecoupementOfPaAndMgPercent
                        }}%
                      </td>

                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn
                                .distributorCorridorPriorToRecoupementOfPaAndMgTotal
                            )
                          }}</b
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                        :colspan="reportTypeValue == 'default' ? 7 : 5"
                        class="text-left"
                      >
                        Licensor Corridor Prior To Recoupement Of P&A -
                        {{
                          royaltyReportData.licensorCorridorPriorToRecoupementOfPaPercent
                        }}%
                      </td>
                      <td v-if="reportTypeValue != 'default'" class="text-left">
                        <span
                          v-for="(terr, terrIndex) in optionTempTerritory"
                          :key="terrIndex"
                        >
                          {{ terr.ter }}
                          <br
                        /></span>
                      </td>
                      <td v-if="reportTypeValue != 'default'">
                        <span
                          v-for="(
                            main, indexmain
                          ) in royaltyReportData.licensorCorridorPriorToRecoupementOfPaSection"
                          :key="indexmain"
                        >
                          <b>
                            {{ royaltyReportData.totalColumn.currency }}
                            {{ formatCurrency(main.totalValue) }} </b
                          ><br
                        /></span>
                      </td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn
                                .licensorCorridorPriorToRecoupementOfPaSectionTotal
                            )
                          }}</b
                        >
                      </td>
                    </tr>
                    <tr>
                      <td colspan="7" class="text-left">
                        Licensor Corridor Prior To Recoupement Of MG -
                        {{
                          royaltyReportData.licensorCorridorPriorToRecoupementOfMgPercent
                        }}%
                      </td>

                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn
                                .licensorCorridorPriorToRecoupementOfMgTotal
                            )
                          }}</b
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                        :colspan="reportTypeValue == 'default' ? 7 : 5"
                        class="text-left"
                      >
                        Total Less
                      </td>
                      <td v-if="reportTypeValue != 'default'" class="text-left">
                        <span
                          v-for="(terr, terrIndex) in optionTempTerritory"
                          :key="terrIndex"
                        >
                          {{ terr.ter }}
                          <br
                        /></span>
                      </td>
                      <td v-if="reportTypeValue != 'default'">
                        <span
                          v-for="(
                            main, indexmain
                          ) in royaltyReportData.lessTotalSection"
                          :key="indexmain"
                        >
                          <b>
                            {{ royaltyReportData.totalColumn.currency }}
                            {{ formatCurrency(main.totalValue) }}</b
                          >
                          <br
                        /></span>
                      </td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn.lessSectionTotal
                            )
                          }}</b
                        >
                      </td>
                    </tr>

                    <tr>
                      <td
                        :colspan="reportTypeValue == 'default' ? 7 : 5"
                        class="text-left"
                      >
                        Cinematic Share Post Recoupment
                      </td>
                      <td v-if="reportTypeValue != 'default'" class="text-left">
                        <span
                          v-for="(terr, terrIndex) in optionTempTerritory"
                          :key="terrIndex"
                        >
                          {{ terr.ter }}
                          <br
                        /></span>
                      </td>
                      <td v-if="reportTypeValue != 'default'">
                        <span
                          v-for="(
                            main, indexmain
                          ) in royaltyReportData.cinemeticSharePostRecoupmentSection"
                          :key="indexmain"
                        >
                          <b>
                            {{ royaltyReportData.totalColumn.currency }}
                            {{ formatCurrency(main.totalValue) }} </b
                          ><br
                        /></span>
                      </td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn
                                .cinemeticSharePostRecoupmentSectionTotal
                            )
                          }}</b
                        >
                      </td>
                    </tr>
                    <tr class="spacing">
                      <th colspan="8" class="text-left">Licensor's Shares</th>
                    </tr>

                    <tr>
                      <td
                        :colspan="reportTypeValue == 'default' ? 7 : 5"
                        class="text-left"
                      >
                        Licensor's Share Of Overage -
                        {{ royaltyReportData.licenorShareOfOveragePercent }} %
                      </td>
                      <td v-if="reportTypeValue != 'default'" class="text-left">
                        <span
                          v-for="(terr, terrIndex) in optionTempTerritory"
                          :key="terrIndex"
                        >
                          {{ terr.ter }}
                          <br
                        /></span>
                      </td>
                      <td v-if="reportTypeValue != 'default'">
                        <span
                          v-for="(
                            main, indexmain
                          ) in royaltyReportData.licenorShareOfOverageSection"
                          :key="indexmain"
                        >
                          <b>
                            {{ royaltyReportData.totalColumn.currency }}
                            {{ formatCurrency(main.totalValue) }} </b
                          ><br
                        /></span>
                      </td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn
                                .licenorShareOfOverageSectionTotal
                            )
                          }}</b
                        >
                      </td>
                    </tr>

                    <tr
                      v-for="(
                        main, indexmain
                      ) in royaltyReportData.licensorShareData"
                      :key="indexmain"
                    >
                      <th
                        class="text-left"
                        :colspan="reportTypeValue == 'default' ? 3 : 2"
                        v-if="main.key == 'header'"
                      >
                        {{ main.movieName }}
                      </th>
                      <th v-if="main.key == 'header'">
                        {{ main.acc_revenue }}
                      </th>
                      <th v-if="main.key == 'header'">
                        {{ main.period_revenue }}
                      </th>
                      <th v-if="main.key == 'header'">
                        {{ main.total_revenue }}
                      </th>
                      <th v-if="main.key == 'header'">
                        {{ main.share_percent }}
                      </th>
                      <th v-if="main.key == 'header'">
                        {{ main.amount_after_percent }}
                      </th>

                      <!-- data from here -->
                      <td
                        class="text-left"
                        colspan="2"
                        v-if="main.key == 'data'"
                      >
                        {{ main.movieName }}
                        <span
                          class="text-muted"
                          v-if="main.movieName != main.subrightsNamesString"
                          ><br /><small class="text-muted"
                            >({{ main.subrightsNamesString }})</small
                          ></span
                        >
                      </td>
                      <td v-if="main.key == 'data'">
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{ formatCurrency(main.acc_revenue) }}</b
                        >
                      </td>
                      <td v-if="main.key == 'data'">
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{ formatCurrency(main.period_revenue) }}</b
                        >
                      </td>
                      <td v-if="main.key == 'data'">
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{ formatCurrency(main.total_revenue) }}</b
                        >
                      </td>
                      <td v-if="main.key == 'data'">
                        {{ formatCurrency(main.share_percent) }}%
                      </td>
                      <td v-if="main.key == 'data'">
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{ formatCurrency(main.amount_after_percent) }}</b
                        >
                      </td>
                    </tr>
                    <tr>
                      <td colspan="6" class="text-left">Total</td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn.territorySumTotal
                            )
                          }}</b
                        >
                      </td>
                    </tr>

                    <tr>
                      <td colspan="7" class="text-left">
                        Total Licensor Share
                      </td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn.totalLicensorSum
                            )
                          }}
                        </b>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="7" class="text-left">
                        Total With Licensor Corridor Prior To Recoupment
                      </td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn.totalLicensorSum
                            )
                          }}</b
                        >
                      </td>
                    </tr>

                    <tr class="spacing text-left">
                      <th class="text-left" colspan="8">Less Paid</th>
                    </tr>
                    <tr>
                      <td colspan="7" class="text-left">MG</td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn.lessPaidMgTotal
                            )
                          }}</b
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                        :colspan="reportTypeValue == 'default' ? 7 : 5"
                        class="text-left"
                      >
                        Bumper Paid
                      </td>
                      <td v-if="reportTypeValue != 'default'" class="text-left">
                        <span
                          v-for="(terr, terrIndex) in optionTempTerritory"
                          :key="terrIndex"
                        >
                          {{ terr.ter }}
                          <br
                        /></span>
                      </td>
                      <td v-if="reportTypeValue != 'default'">
                        <span
                          v-for="(
                            main, indexmain
                          ) in royaltyReportData.bumperSection"
                          :key="indexmain"
                        >
                          <b>
                            {{ royaltyReportData.totalColumn.currency }}
                            {{ formatCurrency(main.totalValue) }} </b
                          ><br
                        /></span>
                      </td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn.lessBumperPaidTotal
                            )
                          }}</b
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                        :colspan="reportTypeValue == 'default' ? 7 : 5"
                        class="text-left"
                      >
                        Overage Paid
                      </td>
                      <td v-if="reportTypeValue != 'default'" class="text-left">
                        <span
                          v-for="(terr, terrIndex) in optionTempTerritory"
                          :key="terrIndex"
                        >
                          {{ terr.ter }}
                          <br
                        /></span>
                      </td>
                      <td v-if="reportTypeValue != 'default'">
                        <span
                          v-for="(
                            main, indexmain
                          ) in royaltyReportData.overagePaidSection"
                          :key="indexmain"
                        >
                          <b>
                            {{ royaltyReportData.totalColumn.currency }}
                            {{ formatCurrency(main.totalValue) }} </b
                          ><br
                        /></span>
                      </td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn.lessOveragePaidTotal
                            )
                          }}</b
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                        :colspan="reportTypeValue == 'default' ? 7 : 5"
                        class="text-left"
                      >
                        Divers Paid
                      </td>
                      <td v-if="reportTypeValue != 'default'" class="text-left">
                        <span
                          v-for="(terr, terrIndex) in optionTempTerritory"
                          :key="terrIndex"
                        >
                          {{ terr.ter }}
                          <br
                        /></span>
                      </td>
                      <td v-if="reportTypeValue != 'default'">
                        <span
                          v-for="(
                            main, indexmain
                          ) in royaltyReportData.diverPaidSection"
                          :key="indexmain"
                        >
                          <b>
                            {{ royaltyReportData.totalColumn.currency }}
                            {{ formatCurrency(main.totalValue) }} </b
                          ><br
                        /></span>
                      </td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn.lessDiversPaidTotal
                            )
                          }}</b
                        >
                      </td>
                    </tr>
                    <tr
                      v-for="(
                        other, indexO
                      ) in royaltyReportData.otherRevenueDataSection"
                      :key="indexO"
                    >
                      <td colspan="7" class="text-left">
                        {{ other.revenue_name }}
                      </td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{ formatCurrency(other.sum) }}</b
                        >
                      </td>
                    </tr>
                    <tr>
                      <td colspan="7" class="text-left">
                        {{
                          royaltyReportData.totalColumn.balancePayable &&
                          Number(royaltyReportData.totalColumn.balancePayable) <
                            0
                            ? "Unrecouped"
                            : "Balance To Your Favour"
                        }}
                      </td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn.balancePayable
                            )
                          }}</b
                        >
                      </td>
                    </tr>
                    <tr
                      v-if="
                        royaltyReportData.totalColumn.showRRCoridorSection ==
                        true
                      "
                      class="spacing"
                    >
                      <th colspan="8" class="text-left">
                        Licensor Share of Corridor
                      </th>
                    </tr>
                    <tr
                      v-if="
                        royaltyReportData.totalColumn.showRRCoridorSection ==
                        true
                      "
                    >
                      <td colspan="7" class="text-left">
                        Licensor Share prior to recoupement on P&A
                      </td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn
                                .licensorCorridorPriorToRecoupementOfPaSectionTotal
                            )
                          }}</b
                        >
                      </td>
                    </tr>
                    <tr
                      v-if="
                        royaltyReportData.totalColumn.showRRCoridorSection ==
                        true
                      "
                    >
                      <td colspan="7" class="text-left">
                        Licensor Share prior to recoupement on MG
                      </td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn
                                .licensorCorridorPriorToRecoupementOfMgTotal
                            )
                          }}</b
                        >
                      </td>
                    </tr>
                    <tr
                      v-if="
                        royaltyReportData.totalColumn.showRRCoridorSection ==
                        true
                      "
                    >
                      <td colspan="7" class="text-left">
                        Total Licensor Corridor
                      </td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn.totalLicensorCoridor
                            )
                          }}</b
                        >
                      </td>
                    </tr>

                    <tr
                      v-if="
                        royaltyReportData.totalColumn.showRRCoridorSection ==
                        true
                      "
                    >
                      <td
                        :colspan="reportTypeValue == 'default' ? 7 : 5"
                        class="text-left"
                      >
                        Less Licensor corridor Paid
                      </td>
                      <td v-if="reportTypeValue != 'default'" class="text-left">
                        <span
                          v-for="(terr, terrIndex) in optionTempTerritory"
                          :key="terrIndex"
                        >
                          {{ terr.ter }}
                          <br
                        /></span>
                      </td>

                      <td v-if="reportTypeValue != 'default'">
                        <span
                          v-for="(
                            main, indexmain
                          ) in royaltyReportData.lessCoridorPaidSection"
                          :key="indexmain"
                        >
                          <b
                            >{{ royaltyReportData.totalColumn.currency }}
                            {{ formatCurrency(main.totalValue) }}</b
                          >
                          <br
                        /></span>
                      </td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn
                                .lessLicensorCorridorPaid
                            )
                          }}
                        </b>
                      </td>
                    </tr>

                    <tr
                      v-if="
                        royaltyReportData.totalColumn.showRRCoridorSection ==
                        true
                      "
                    >
                      <td colspan="7" class="text-left">Balance Payable</td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn.balancePayableSecond
                            )
                          }}</b
                        >
                      </td>
                    </tr>

                    <tr
                      v-if="
                        royaltyReportData.totalColumn.showRRCoridorSection ==
                        true
                      "
                    >
                      <td colspan="7" class="text-left">Total Payable</td>
                      <td>
                        <b>
                          {{ royaltyReportData.totalColumn.currency }}
                          {{
                            formatCurrency(
                              royaltyReportData.totalColumn.totalFinalPayable
                            )
                          }}</b
                        >
                      </td>
                    </tr>
                  </table>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  class="m-2"
                >
                  <b-button
                    :disabled="loading ? true : false"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="gradient-primary"
                    class="ml-2"
                    @click="showGenerateConfirm(royaltyReportData)"
                  >
                    <b-spinner v-if="loading" small /> Generate Royalty Report
                  </b-button>
                </div>
                <hr class="m-2" />
                <b-row> </b-row></div
            ></b-tab>
            <b-tab title="Existing Royalty Reports">
              <b-row>
                <b-col cols="12" class="d-flex justify-content-center">
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                      @change="GetSavedReports"
                      id="radio-group-1"
                      v-model="selectedRadio"
                      :options="optionsRadio"
                      :aria-describedby="ariaDescribedby"
                      name="radio-options"
                    ></b-form-radio-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-card no-body>
                <b-table
                  hover
                  small
                  bordered
                  :fields="fields"
                  :items="savedReports"
                  empty-text="No  records found"
                  show-empty
                  responsive
                >
                  <template #cell(Sr)="data">
                    {{ data.index + 1 }}
                    <h6 v-if="data.item.isFinalReport == 1">
                      <b-badge variant="light-danger">{{
                        data.item.isFinalReport == 1 ? "Final/Last" : ""
                      }}</b-badge>
                    </h6>
                    <h6 v-if="data.item.isReportSent == 1">
                      <b-badge variant="light-danger">{{
                        data.item.isReportSent == 1 ? "Sent" : ""
                      }}</b-badge>
                    </h6>
                  </template>
                  <template #cell(dateFrom)="data">
                    {{
                      data.value
                        ? moment(data.item.dateFrom).format("DD/MM/YYYY") +
                          " to " +
                          moment(data.item.dateTo).format("DD/MM/YYYY")
                        : ""
                    }}
                  </template>

                  <template #cell(balance_to_favor)="data">
                    <b>{{
                      "CHF " + formatCurrency(data.item.balance_to_favor)
                    }}</b>
                  </template>

                  <template #cell(createdAt)="data">
                    {{
                      data.value ? moment(data.value).format("DD/MM/YYYY") : ""
                    }}
                  </template>

                  <template #cell(actions)="data">
                    <div class="text-nowrap">
                      <b-button
                        class="mr-1"
                        variant="outline-primary"
                        size="sm"
                        @click="onPreviewRoyalty(data.item.royalty_report_id)"
                        >Preview</b-button
                      >

                      <b-button
                        variant="outline-danger"
                        size="sm"
                        @click="
                          onDownloadRoyalty(
                            data.item.royalty_report_document,
                            data.item.company
                          )
                        "
                        >Download</b-button
                      >
                      <br />

                      <b-button
                        block
                        class="mt-1"
                        variant="outline-warning"
                        size="sm"
                        @click="
                          updateFinalStatus(
                            data.item.royalty_report_id,
                            data.item.isFinalReport,
                            data.item.isReportSent,
                            'sent'
                          )
                        "
                        >{{
                          data.item.isReportSent == 0 ? "Set " : "Unset"
                        }}
                        Report as Sent</b-button
                      >
                      <b-button
                        block
                        class="mt-1"
                        variant="outline-warning"
                        size="sm"
                        @click="
                          updateFinalStatus(
                            data.item.royalty_report_id,
                            data.item.isFinalReport,
                            data.item.isReportSent,
                            'final'
                          )
                        "
                        >{{
                          data.item.isFinalReport == 0 ? "Set " : "Unset"
                        }}
                        Report as final/last</b-button
                      >
                      <!-- <b-tooltip
                        title="Preview Report"
                        class="cursor-pointer"
                        :target="`invoice-row-${data.item.royalty_report_id}-send-icon`"
                      /> -->
                    </div>
                  </template>
                </b-table>
                <template #footer>
                  <div class="mx-2 mb-2">
                    <b-row>
                      <!-- Pagination -->
                      <b-col
                        class="d-flex align-items-center justify-content-center justify-content-sm-center"
                      >
                        <b-pagination
                          @change="onPageChange"
                          v-model="pagination.currentPage"
                          :total-rows="pagination.total"
                          :per-page="pagination.perPage"
                          first-number
                          last-number
                          class="mb-0 mt-1 mt-sm-0"
                          prev-class="prev-item"
                          next-class="next-item"
                        >
                          <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                          </template>
                          <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                          </template>
                        </b-pagination>
                      </b-col>
                    </b-row>
                  </div>
                </template> </b-card
            ></b-tab>
            <b-tab title="Pending Royalty Reports As Per Statement Period">
              <!-- <template #title>
                Pending Royalty Reports As Per Statement Period
                {{ contractsList.length
                }}<b-badge v-if="contractsList.length" variant="primary"
                  >4</b-badge
                >
              </template> -->
              <div v-if="tabIndex == 2">
                <pending-royalty-report
                  ref="pendingComponentRef"
                  @setReportDataEmit="getReportData"
                  :selectedVendorId="
                    selectedCustomer ? selectedCustomer.vendor_id : null
                  "
                  :selectedMovieId="
                    selectedMovie && selectedMovie.movie_id
                      ? selectedMovie.movie_id
                      : null
                  "
                />
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-col>
    </b-row>
    <div>
      <b-modal
        scrollable
        v-model="modalContract"
        id="modalContract"
        size="xl"
        hide-footer
      >
        <b-table
          bordered
          class="position-relative"
          primary-key="contract_id"
          responsive
          show-empty
          :items="contractsList"
          :fields="fieldsContract"
          hover
        >
          <!-- hover -->
          <template #head(selected)="data">
            <b-form-checkbox
              @change="selectAllRows"
              v-model="selectAllStatus"
              value="1"
              unchecked-value="0"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(selected)="data">
            <b-form-checkbox
              v-model="selectedRotaltyContracts"
              :value="data.item"
            >
            </b-form-checkbox>
          </template>
          <template #cell(contract_id)="data">
            {{ data.item.contract_id }}
          </template>

          <template #cell(company)="data">
            <div style="width: max-content">
              <b-badge variant="light-success">
                {{ data.item.contract_nature }}
                {{ data.item.draft ? "(draft)" : "" }}</b-badge
              ><br />{{ data.item.company }}
            </div>
          </template>
          <template #cell(title)="data">
            <div style="width: max-content" v-html="data.value"></div>
          </template>
          <template #cell(territory)="data">
            <span>
              {{ data.value }}
            </span>
          </template>
          <template #cell(right)="data">
            <span>
              {{ data.value }}
            </span>
          </template>
          <template #cell(language)="data">
            <span>
              {{ data.value }}
            </span>
          </template>
          <template #cell(purchaseDate)="data">
            <b-badge variant="light-info"
              >Contract Date : {{ data.item.purchaseDate }} </b-badge
            ><br />
            <b-badge variant="light-success" class="mt-1">
              Start : {{ data.item.rStart }} </b-badge
            ><br />

            <b-badge variant="light-danger" class="mt-1">
              End : {{ data.item.rExpiry }}</b-badge
            >
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                variant="primary"
                size="sm"
                @click="getRoyaltyReportMultiple(data.item)"
              >
                Preview Report </b-button
              ><br />
              <b-badge
                variant="warning"
                v-if="selectedContractID === data.item.contract_id"
                >currently on preview</b-badge
              >

              <!-- <b-button
                size="sm"
                @click="data.toggleDetails, getRoyaltyReportMultiple(data.item)"
              >
                {{ data.detailsShowing ? "Hide" : "Show" }} Details
              </b-button> -->
            </div>
          </template>
          <template #row-details="row"> </template>
          <template #cell()="data">
            <span class="text-nowrap">{{ data.value }}</span>
          </template>
        </b-table>
      </b-modal>
    </div>
    <b-modal
      scrollable
      v-model="modalPreview"
      id="modalPreview"
      size="xl"
      hide-footer
    >
      <preview-invoice
        :selected_royalty_report_id="selected_royalty_report_id"
      />
    </b-modal>

    <!-- <invoice-sidebar-send-invoice />
    <invoice-sidebar-add-payment /> -->
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import Treeselect from "@riophae/vue-treeselect";
import { labels } from "@/utils/constants";
import * as ContractServices from "@/apiServices/ContractServices";
import previewInvoice from "./PreviewRoyaltyReport.vue";
import pendingRoyaltyReport from "./PendingRoyaltyReports.vue";

import {
  GetRoyaltyReportDataTerritory,
  GetRoyaltyReportList,
  GenerateRoyaltyReportPDF,
  GetMoviesVendor,
  GetVendorMovies,
} from "@/apiServices/RevenueServies";
import { formatCurrency } from "@/utils/helpers";

import { getAllMovie } from "@/apiServices/MovieServices";
import { getAllVendor } from "@/apiServices/VendorServices";

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BAlert,
  BLink,
  VBToggle,
  BTable,
  BTooltip,
  BSpinner,
  BPagination,
  BTabs,
  BTab,
  BModal,
  BBadge,
  BFormRadio,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
// import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
// import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'

export default {
  components: {
    BRow,
    BTabs,
    BTab,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BAlert,
    BLink,
    flatPickr,
    vSelect,
    Logo,
    BTable,
    BTooltip,
    ToastificationContentVue,
    BSpinner,
    BPagination,
    BModal,
    Treeselect,
    BBadge,
    BFormRadio,
    previewInvoice,
    BFormRadioGroup,
    pendingRoyaltyReport,
    BListGroup,
    BListGroupItem,
    // InvoiceSidebarSendInvoice,
    // InvoiceSidebarAddPayment,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },

  data() {
    return {
      selectedRadio: "all",
      optionsRadio: [
        { text: "All", value: "all" },
        { text: "Sent", value: "isReportSent" },
        { text: "Final/Last", value: "isFinalReport" },
      ],
      BASE_URL_FILES: process.env.VUE_APP_FILESURL,
      moment: moment,
      pagination: {
        currentPage: 1,
        total: null,
        perPage: 10,
      },
      selectAllStatus: "0",
      selectedRotaltyContracts: [],
      selected_royalty_report_id: null,
      perPageOptions: [10, 25, 50, 100],
      fields: [
        "Sr",
        { key: "company", label: "Licensor" },
        // { key: "movie_title", label: "Movie" },

        // { key: "territory_name", label: "Territory" },
        { key: "dateFrom", label: "Duration" },
        { key: "balance_to_favor", label: "Balance to favor/Unrecouped" },

        { key: "createdAt", label: "Report Generate Date" },
        { key: "reportSentTo", label: "Sent to" },
        { key: "reportDescription", label: "Comments" },
        { key: "actions", label: "Actions" },
      ],
      selectedCustomer: null,
      orderValue: "vendor",
      reportTypeValue: "default",
      loading: false,
      tabIndex: 0,
      modalContract: false,
      modalPreview: false,
      revenueDateRange: null,
      optionCustomer: [],
      optionCustomerTemp: [],
      optionTempTerritory: [],
      optionSearchTerritory: [],
      selectedTempTerritory: [],
      optionMovies: [],
      optionMultipleMovies: [],
      optionVendors: [],
      optionAvailableTeritory: [],

      selectedMovie: null,
      movieName: null,
      reportContractId: null,
      selectedContractID: null,
      optionTeritory: [],
      contractsList: [],
      allTeritory: [],
      savedReports: [],
      vendorAllReports: [],
      trl_territories: [],
      selectedTeritory: null,
      royaltyReportData: null,
      isFinalReport: false,
      finalReportstatus: false,
      isGenerateAll: false,
      reportDescription: null,
      reportSentTo: null,
      reportSearchSection: true,
      showReportSearchSection: true,
      fieldsContract: [
        { key: "selected", label: "", stickyColumn: true },

        {
          key: "actions",
          label: "Actions",
          stickyColumn: true,
          variant: "primary",
        },

        {
          key: "company",
          label: "Contact",
        },
        {
          key: "title",
          label: "Movie",
        },
        {
          key: "territory",
          label: "territory",
        },
        {
          key: "right",
          label: "rights",
        },
        {
          key: "language",
          label: "languages",
        },
        {
          key: "purchaseDate",
          label: "contract dates",
        },

        {
          key: "language",
          label: "languages",
        },
        { key: "contract_id", label: "Contract ID" },
      ],
    };
  },

  mounted() {},

  async beforeMount() {
    this.GetSavedReports();
    this.GetAllVendor();
    this.GetAllMovie();
    // await this.$store.dispatch("master/setLabelTreeMaster", {
    //   key: labels.territory_tree,
    //   master: labels.territory,
    //   sub_master: labels.sub_territory,
    // });

    // this.trl_territories = this.$store.state.master.territory_tree;
  },
  watch: {
    // limit() {
    //   this.getAllRevenueData();
    // },
    // currentPage() {
    //   this.getAllRevenueData();
    //   this.$refs["table_container"].scrollIntoView({ behavior: "smooth" });
    // },
    selectedRotaltyContracts() {
      if (this.selectedRotaltyContracts.length > 0) {
        if (this.selectedRotaltyContracts.length != this.contractsList.length) {
          this.selectAllStatus = "0";
        } else {
          this.selectAllStatus = "1";
        }
      } else {
      }
    },
  },
  methods: {
    formatCurrency,

    async onReportChange() {
      if (this.reportTypeValue == "default") {
        this.$router.push(`/generateroyalty`);
      }
    },

    async onDownloadRoyalty(file, outputFileName) {
      const fileName = this.BASE_URL_FILES + file;

      try {
        const response = await fetch(fileName);
        const blob = await response.blob();
        const url = await URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = outputFileName + ".pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (err) {
        console.log({ err });
      }
    },
    async onGenrateAll() {
      this.isGenerateAll = true;

      for (let i of this.selectedRotaltyContracts) {
        this.loading = true;
        await this.getRoyaltyReportMultiple(i);

        await this.GenerateRoyaltyReportPdf(this.royaltyReportData);
      }
      this.loading = false;
      this.tabIndex = 1;
      this.GetSavedReports();
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: "Generated Succesfully",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },
    selectAllRows() {
      if (this.selectAllStatus == "1") {
        this.selectedRotaltyContracts = this.contractsList;
      } else {
        this.selectedRotaltyContracts = [];
      }
    },
    async getReportData(data) {
      let nextFromDate = data.lastReportCreatedDate
        ? moment(data.lastReportCreatedDate).format("YYYY-MM-DD")
        : moment(data.contractCreatedDate).format("YYYY-MM-DD");
      let nextToDate = null;
      if (data.statementPeriod == "1") {
        nextToDate = moment(nextFromDate).add(1, "months");
      } else if (data.statementPeriod == "2") {
        nextToDate = moment(nextFromDate).add(6, "months");
      } else if (data.statementPeriod == "3") {
        nextToDate = moment(nextFromDate).add(3, "months");
      } else if (data.statementPeriod == "4") {
        nextToDate = moment(nextFromDate).add(12, "months");
      }
      nextToDate = moment(nextToDate).format("YYYY-MM-DD");
      this.revenueDateRange = nextFromDate + " to " + nextToDate;
      this.getRoyaltyReportMultiple(data);
    },
    async onPreviewRoyalty(id) {
      this.selected_royalty_report_id = id;
      this.modalPreview = true;
    },
    async onOrderChange() {
      this.selectedMovie = null;
      this.selectedCustomer = null;
      this.revenueDateRange = null;
      this.royaltyReportData = null;
      if (this.orderValue == "vendor") {
        //this.GetAllVendor();
      } else if (this.orderValue == "movie") {
        // this.GetAllMovie();
      }
    },
    async GetSavedReports() {
      try {
        let payload = {
          page: this.pagination.currentPage,
          reportContractId: this.reportContractId,
          limit: this.pagination.perPage ? this.pagination.perPage : null,
          vendor_id:
            this.selectedCustomer && this.selectedCustomer.vendor_id
              ? this.selectedCustomer.vendor_id
              : null,
          movie_id:
            this.selectedMovie && this.selectedMovie.movie_id
              ? this.selectedMovie.movie_id
              : null,
          territory_id: this.selectedTeritory ? this.selectedTeritory : null,
          selectedRadio: this.selectedRadio,
          // range: this.revenueDateRange
          //   ? this.revenueDateRange.split("to")
          //   : null,
        };

        const response = await GetRoyaltyReportList(payload);

        if (!response.status) {
          return this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Data not found!",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.savedReports = response.data.data.data;
        if (
          response.data.data.hasOwnProperty("pagination") &&
          response.data.data.pagination.total
        ) {
          this.pagination.total = response.data.data.pagination.total;
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },
    async GetAllMovie() {
      try {
        const response = await getAllMovie();
        if (response.data) {
          this.optionMovies = response.data.data.data;
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },

    async GetAllVendor() {
      try {
        const response = await getAllVendor({});
        if (response.data) {
          this.optionVendors = response.data.data.data;
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },
    async updateFinalStatus(reportId, finalStatus, isReportSent, type) {
      try {
        let final;
        let sent;
        if (type == "final") {
          final = finalStatus && finalStatus == 1 ? 0 : 1;
        } else {
          final = finalStatus;
        }

        if (type == "sent") {
          sent = isReportSent && isReportSent == 1 ? 0 : 1;
        } else {
          sent = isReportSent;
        }

        const response = await GenerateRoyaltyReportPDF({
          royalty_report_id: reportId,
          finalStatus: final,
          isReportSent: sent,
        });
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.GetSavedReports();
        }
      } catch (error) {
        console.log("Error in updating status");
      }
    },
    onSelect(selectedMovie) {
      // console.log(selectedMovie);
      // let getData = this.vendorAllReports.filter((z) => {
      //   return (
      //     z.movie_id == selectedMovie.multi_movies_ids &&
      //     String(z.vendor_id) == String(selectedMovie.vendor_id)
      //   );
      // });
      // console.log(getData, "movie");
      // if (getData.length) {
      //   this.revenueDateRange = getData[0].dateTo;
      // } else {
      //   this.revenueDateRange = "";
      // }
    },
    onTabChange() {
      this.selectedMovie = null;
      this.movieName = null;
      this.selectedCustomer = null;
      this.revenueDateRange = null;
    },

    onPageChange() {
      this.$nextTick(() => {
        this.GetSavedReports();
      });
    },

    async getLicensorMovies(isMultiple) {
      this.optionMultipleMovies = [];
      this.vendorAllReports = [];
      this.selectedMovie = null;
      try {
        const response = await GetVendorMovies({
          vendor_id: this.selectedCustomer.vendor_id,
          isMultiple: isMultiple,
        });
        if (response.data.status) {
          this.optionMultipleMovies = response.data.data.vendors;
          this.vendorAllReports = response.data.data.reportStrtDate;
          if (this.optionMultipleMovies.length == 1) {
            this.selectedMovie = this.optionMultipleMovies[0];
            //this.onSelect(this.selectedMovie);
          }
        }
      } catch (error) {
        console.log("Error in getting Contracts List");
      }
    },
    async getMoviesLicensor() {
      this.optionCustomer = [];
      this.selectedCustomer = null;

      try {
        const response = await GetMoviesVendor({
          movie_id: this.selectedMovie.movie_id,
        });
        if (response.data.status) {
          this.optionCustomer = response.data.data;
        }
      } catch (error) {
        console.log("Error in getting Contracts List");
      }
    },
    searchSavedReports() {
      this.tabIndex = this.tabIndex;
      this.royaltyReportData = null;
      if (this.tabIndex == 2) {
        this.$refs.pendingComponentRef.getContractsList();
      }

      this.GetSavedReports();
    },

    async GenerateRoyaltyReportPdf(payload) {
      payload["Balance_to_your_favor"] =
        payload.totalColumn.actualBalancePayable;
      this.loading = true;
      try {
        payload.searchValues = {
          vendor_id: this.selectedCustomer.vendor_id,
          movie_id:
            this.selectedMovie && this.selectedMovie.movie_id
              ? this.selectedMovie.movie_id
              : null,
          reportContractId: this.reportContractId,
          territory_id: 0,
          expense_date: this.revenueDateRange,
        };
        let vendorData = this.optionVendors.filter((x) => {
          return x.vendor_id == this.selectedCustomer.vendor_id;
        });
        if (vendorData.length) {
          this.selectedCustomer = vendorData[0];
        }
        payload.headData = {
          territory_name: "",
          tilte_name:
            this.selectedMovie && this.selectedMovie.title
              ? this.selectedMovie.title
              : null,
          vendor_name: this.selectedCustomer,
        };
        payload.isFinalReport = this.isFinalReport == true ? 1 : 0;

        payload.actualBalancePayable =
          this.royaltyReportData.totalColumn.actualBalancePayable;
        payload.reportDescription = this.reportDescription;
        payload.reportSentTo = this.reportSentTo;
        payload.isRRTerritoryWise = true;
        let tempReportHtml = this.$refs.rrt_table;
        payload.reportHtml = tempReportHtml.outerHTML;

        // /console.log(payload.reportHtml.outerHTML, "hh");

        const response = await GenerateRoyaltyReportPDF(payload);
        if (response.data.status) {
          if (this.isGenerateAll == false) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "PDF Generated Succesfully",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.selected_royalty_report_id =
              response.data.data.royalty_report_id;
            this.modalPreview = true;
            this.GetSavedReports();
          }
          // this.$router.push({
          //   name: "PreviewRoyaltyReport",
          //   params: {
          //     royalty_report_id: response.data.data.royalty_report_id,
          //   },
          // });
          //this.searchSavedReports();
          this.loading = false;

          //this.optionCustomer = response.data.data.rows;
        }
      } catch (err) {
        this.loading = false;
        console.log("Error in adding ", err);
      }
    },

    async getReportContracts() {
      try {
        const response = await ContractServices.getAllContract({
          movie:
            this.selectedMovie && this.selectedMovie.multi_movies_ids
              ? this.selectedMovie.multi_movies_ids
              : this.selectedMovie && this.selectedMovie.movie_id
              ? this.selectedMovie.movie_id
              : null,
          vendor: this.selectedCustomer
            ? this.selectedCustomer.vendor_id
            : null,
          contract_nature: "Acquisition",
        });
        this.contractsList = response.data.data.contracts;

        if (!response.data.data.contracts.length) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Acquisition Contract Not Found!",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        //  else if (this.contractsList.length == 1) {
        //   this.getRoyaltyReportMultiple(this.contractsList[0].contract_id);
        // }
        else {
          this.modalContract = true;
        }
      } catch (error) {
        console.log("Error in getting Contracts List", error);
      }
    },
    async getRoyaltyReportMultiple(contractData) {
      this.optionTempTerritory = [];
      this.royaltyReportData = null;
      this.selectedContractID = contractData.contract_id;
      this.tabIndex = 0;
      let obj = {
        vendor_id: contractData.vendor_id,
        company: contractData.company,
      };

      this.selectedCustomer = obj;

      this.modalContract = false;
      this.reportContractId = contractData.contract_id;

      try {
        let payload = {
          contractID: contractData.contract_id,
          vendor_id: contractData.vendor_id,
          movie_ids: contractData.movie_ids
            ? contractData.movie_ids.split(",").map(Number)
            : [],
          movie_names: contractData.title
            ? contractData.title.split("<br/>")
            : [],
          territory_names: contractData.territory
            ? contractData.territory.split(",")
            : [],
          territory_ids: this.selectedTempTerritory.length
            ? this.selectedTempTerritory
            : contractData.territory_ids
            ? contractData.territory_ids.split(",").map(Number)
            : [],
          rrType: this.reportTypeValue,
          fromDate: this.revenueDateRange
            ? this.revenueDateRange.split("to")[0]
            : "",
          toDtae: this.revenueDateRange
            ? this.revenueDateRange.split("to")[1]
            : "",
        };
        const response = await GetRoyaltyReportDataTerritory(payload);
        if (response.data) {
          console.log(response.data.data, "response.data.data");
          this.royaltyReportData = response.data.data;
          if (!this.optionTempTerritory.length) {
            let teriName =
              this.royaltyReportData.totalColumn.contractTerritories.split(",");
            let teriId =
              this.royaltyReportData.totalColumn.territory_ids_array.split(",");

            for (let i = 0; i < teriName.length; i++) {
              let p = teriName[i];

              this.optionTempTerritory.push({
                ter: p,
                id: teriId[i],
              });
            }
          }

          if (!this.optionSearchTerritory.length) {
            let teriName =
              this.royaltyReportData.totalColumn.contractTerritories.split(",");
            let teriId =
              this.royaltyReportData.totalColumn.territory_ids_array.split(",");

            for (let i = 0; i < teriName.length; i++) {
              let p = teriName[i];

              this.optionSearchTerritory.push({
                ter: p,
                id: teriId[i],
              });
            }
          }

          // this.GetSavedReports();
        }
      } catch (err) {
        console.log("Error in getting  list ", err);
      }
    },
    showGenerateConfirm(data) {
      this.$bvModal
        .msgBoxConfirm(
          "Once royalty report gets generated it will get added to system logs!.Are you sure, you want to proceed with generate royalty report?",
          {
            title: "Confirmation",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) this.GenerateRoyaltyReportPdf(data);
        })
        .catch((err) => {
          // An error occurred
        });
    },
    resetForm() {
      this.selectedMovie = null;
      this.selectedCustomer = null;
      this.revenueDateRange = null;
      this.reportContractId = null;
      this.selectedRotaltyContracts = [];
      this.royaltyReportData = null;
      this.GetSavedReports();
    },
  },
  created() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td {
  white-space: nowrap;
}

td,
th {
  border: 1px solid #000;
  text-align: right;
  padding: 5px;
}
.spacing {
  background-color: #eaecfd;
}
br {
  border: 1px solid #000;
}
table {
  font-family: verdana !important;
}
smallWidth {
  max-width: 100px !important;
}
table {
  table-layout: fixed !important;
}
td {
  width: 33% !important;
}
</style>
